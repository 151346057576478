<template>
  <div class="new_list" v-loading="loading" element-loading-text="拼命加载中">
    <div class="container">
      <div class="breadcrumb">
        <span class="name">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/news/'  }"
            >政策要闻</el-breadcrumb-item
          >
          <el-breadcrumb-item>政策法规</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <h1>
        <img src="@/static/news/policy.png" alt="" class="icon img" />政策法规
      </h1>
      <div class="content flex">
        <div class="left">
          <ul class="tab" v-if="locationSite != '河南省'">
            <li v-for="(n, i) in tabList" :key="i" @click="tabs(i, n)">
              <span :class="{ active: active == i }">{{ n }}</span>
            </li>
          </ul>
          <ul class="list">
            <li
              class="box"
              v-for="(n, i) in list"
              :key="i"
              @click="goRouter('/news/newDetail', n.id)"
            >
              <h2 class="line_clamp2">
                <a href="javascript:void(0);">{{ n.title }}</a>
              </h2>
              <div class="bottom flex">
                <img
                  :src="n.thumbnail"
                  alt=""
                  class="banner"
                  v-if="n.thumbnail"
                />
                <div class="info relative">
                  <div class="text line_clamp2">
                    {{ n.conAbstract }}
                  </div>
                  <div class="time">
                    {{ n.createTime }}
                    <span>{{ n.titleAbbr }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <status :type="status.type" v-if="status.isShow"></status>
          <div class="pages">
            <el-pagination
              background
              class="work_pagination"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.pageNum"
              :page-size="page.pageSize"
              layout="prev, pager, next, total, jumper"
              v-show="page.total > 10"
              :total="page.total"
              prev-text="上一页"
              next-text="下一页"
            >
            </el-pagination>
          </div>
        </div>
        <div class="right">
          <div class="box">
            <div class="title">
              <h2>新闻动态</h2>
              <i
                class="icon icon el-icon-d-arrow-right"
                @click="goRouter('/news/new_list')"
                title="更多"
              ></i>
            </div>
            <ul>
              <li class="first flex">
                <div class="info" v-for="(n, i) in list.slice(0, 2)" :key="i">
                  <img
                    :src="n.thumbnail"
                    :alt="n.titile"
                    :title="n.title"
                    class="banner err_image"
                  />
                  <a
                    href="javascript:void(0);"
                    :title="n.title"
                    class="line_clamp1"
                    @click="goRouter('/news/newDetail', n.id)"
                    >{{ n.title }}</a
                  >
                </div>
              </li>
              <li
                v-for="(n, i) in list.slice(2, 8)"
                :key="i"
                class="li line_clamp1"
              >
                <a
                  href="javascript:void(0);"
                  :title="n.title"
                  @click="goRouter('/news/newDetail', n.id)"
                >
                  <span v-show="n.author">「{{ n.author }}」</span
                  >{{ n.title }}
                </a>
              </li>
            </ul>
          </div>
          <div v-for="(n, i) in adList" :key="i" class="relative">
            <router-link :to="n.picUrl" target="_blank" :title="n.adName">
              <img
                :src="n.picSrc"
                alt="广告"
                :title="n.adName"
                class="ad_img"
              />
            </router-link>
            <span>广告</span>
          </div>
          <div class="box">
            <div class="title">
              <h2>通知公告</h2>
              <i
                class="icon icon el-icon-d-arrow-right"
                @click="goRouter('/news/msg_list')"
                title="更多"
              ></i>
            </div>
            <ul>
              <li v-for="(n, i) in list2" :key="i" class="li line_clamp1">
                <a
                  href="javascript:void(0);"
                  :title="n.title"
                  @click="goRouter('/news/newDetail', n.id)"
                >
                  <span v-show="n.author">「{{ n.author }}」</span
                  >{{ n.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "policy_list",
  metaInfo: {
    title: "兰州政策法规-兰州市残疾人就业创业网络服务平台",
    meta: [
      {
        name: "keywords",
        content: "残疾人就业,残疾人证,兰州市残疾人联合会,中国残联,",
      },
      {
        name: "description",
        content:
          "残疾人资讯平台为广大残疾人、残疾人工作者和更为广泛的关心、关注兰州市残疾人事业的群体提供全面、及时、准确的服务。",
      },
    ],
  },
  data() {
    return {
      active: 0,
      tabList: [ "本省", "本市"],
      list: [],
      list1: [],
      list2: [],
      adList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        city: "兰州",
        leve: "本省",
        consultationClassName: "兰州政策法规",
      },
      loading: false,
      locationSite:'',
      status:{
        type:'record',
        isShow:'',
      },
      path:'/news',
      cityType:'',
    };
  },
  methods: {
    tabs(i, val) {
      this.active = i;
      this.page.leve = val;
      this.policy();
    },
    //政策
    policy() {
      this.loading = true;
      this.page.consultationClassName = this.cityType+this.page.consultationClassName;
      this.$api
        .newsListApi(this.page)
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list = res.data.data.records;
            this.page.total = res.data.data.total;
          }
          if (this.list <= 0){
            this.status.isShow = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.status.type = 'wifi';
          this.status.isShow = true;
        });
    },
    //新闻
    news() {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 10,
          city: this.page.city,
          leve:this.page.leve,
          consultationClassName: this.cityType+'兰州新闻动态',
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list1 = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //通知
    message() {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 10,
          city: this.page.city,
          leve:this.page.leve,
          consultationClassName: this.cityType+'兰州通知公告',
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list2 = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //广告
    getAdApi() {
      this.$api
        .adApi({ classId: "40288113735a75d901735a75d9a40000" })
        .then((res) => {
          this.adList = res.data.data;
        });
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.policy();
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    let locationText = localStorage.getItem('locationSelect');
    this.locationSite = localStorage.getItem('locationSite');
    this.policy();
    this.news();
    this.message();
  },
  mounted() {
    this.$emit("goRouter", "/news/");
  },
};
</script>

<style scoped lang="less">
@import "../../assets/css/news.css";
</style>
